/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
$gray: #58595B; //rgb(88,89,91)
$gray-light: #D1D3D4; //rgb(209,211,212)
$gray-dark: #181818; //rgb(24,24,24)
$white: #FFFFFF;
$lightbrown: #9F968A;
$mediumbrown: #736C64;
$formbrown: #F5F4F3;
$bgbrown: rgba(151,131,122, 0.2);
$portmenucolor: rgba(151,131,122, 0.8);
