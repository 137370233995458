header {
	background-color: white;
	position: absolute;
	top: 0;
	z-index: 1001;
	width: 100%;
	color: $lightbrown;
	padding: 10px 20px;
	a.logo {
		img {
			width: 230px;
			display: inline-block !important;
			max-width: 230px;
			margin-top: 15px;
		}
	}
	nav {
		width: 100%;
		display: inline-block !important;
		ul#theMenu {
			margin-bottom: 0px;
			a {
				text-decoration: none;
				&.menu-portfolio {
					cursor: pointer;
				}
			}
			padding-left: 0;
			text-align: right;
			li {
				list-style: none;
				display: inline-block;
				a {
					color: $lightbrown;
					font-family: $font-montserrat;
					font-size: 0.87rem;
					display: inline-flex;
					text-transform: uppercase;
					padding: 1rem;
					&:hover {
						color: gray;
						text-decoration: none;
					}
					&.lang {
						text-transform: none;
					}
				}
				&.active, &:hover {
					a {
						color: $mediumbrown;
						text-decoration: none;
					}
				}
			}
		}
	}

	#Hamburguer {
		cursor: pointer;
		text-align: center;
		color: $lightbrown;
		padding: 0 2rem;
		font-size: 2.5em;
		@media (min-width: 768px) {
			display: none !important;
		}
	}

	@media (max-width: 768px) {
		padding: 1rem;
		a.logo {
			width: 100%;
			margin-bottom: 0.5rem;
			display: block !important;
			text-align: center;
		}
		nav {
			.isotipomenu {
				width: 10%;
				margin-top: 10px;
				margin-bottom: 10px;
			}
			.port-menu {
				position: fixed;
				left: 0;
				bottom: 100%;
				width: 100%;
				height: 100%;
				background-color: white;
				color: $lightbrown;
				z-index: 10000;
				overflow: scroll;
				transition: all ease 0.3s;
				ul#theMenu {
					text-align: center;
					padding: 0;
					li {
						float: none;
						text-align: center;
						width: 100%;
						a {
							text-align: center;
							width: 100%;
							padding: 1.2rem;
							display: block;
							color: $lightbrown;
							line-height: 50px;
							&:hover, &.active {

							}
						}
					}
					li.closeMenu {
						a {
							color: $lightbrown;
							i {
								font-size: 35px;
							}
						}
					}
				}
				.social-media {
					padding-left: 25%;
					padding-right: 25%;
				}
				&.show-menu {
					bottom: 0;
				}
			}
		}
	}
}

.box-shadow-head {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 160px;
	background: linear-gradient(180deg, rgba(0,0,0,0.5), rgba(0, 0, 0, 0.005));
}
