@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700);

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');

@font-face {
	font-family: 'Futura'; font-style: normal; font-weight: 400;
	src: url('/fonts/futura-light.ttf');
}

$font-poppins: 'Poppins', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;
$futura: 'Futura', sans-serif;