.page-header {
 	height: 300px;
	position: relative;
	overflow: hidden;
	margin-bottom: 3rem;

	h1 {
		position: absolute;
		width: 100%;
		color: #FFFFFF;
		top: 50%;
		transform: translatey(-50%);
		font-size: 3.75rem;
		letter-spacing: 10px;
		text-align: center;
		z-index: 2;
	}
	.parallax {
		position: absolute;
		height: 150%;
		width: 100%;
		z-index: -1;
	}

	&.int-portfolio {
		height: 600px;
		text-align: center;
		img {
			position: absolute;
			width: 60px;
			top: 65%;
			transform: translatex(-50%);
		}
		h1 {
    		top: 85%;
    		span {
    			display: block;
    			text-transform: none;
    			font-size: 25px;
    			letter-spacing: normal;
    			font-weight: 200;
    		}
		}
	}
	@media (max-width: 768px) {
		h1 {
			font-size: 2rem;
			top: 90%;
		}
	}

	@media (min-width: 1200px) {
		.int-portfolio {
			height: 700px;
		}
	}
}