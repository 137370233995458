ul.social {
	list-style: none;
	text-align: center;
	margin: 0;
	padding: 0;
	li {
		display: inline-block;
		a {
			font-size: 1rem;
			padding: 0 0.2rem;
			color: $lightbrown;
			&:hover {
				color: $mediumbrown;
			}
		}
	}
}

.box-shadow {
	position: absolute;
	top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    z-index: 1;
	pointer-events: none;
}

.text-box {
	position: absolute;
	width: 100%;
	top: 50%;
	transform: translateY(-50%);
}
/*
|--------------------------------------------------------------------------
| Aligns
|--------------------------------------------------------------------------
*/
.right {
	text-align: right;
}

/*
|--------------------------------------------------------------------------
| Separators
|--------------------------------------------------------------------------
*/
.separator {
	border: none;
	background-color: transparent !important;
	height: 1px;
	box-shadow: none;
	display: block;
	margin:  15px 0;
	width: 100%;
	clear: both;
}

.lg-separator {
	@extend .separator;
	margin: 30px 0;
}

.xl-separator {
	@extend .separator;
	margin: 60px 0;
}

/*
|--------------------------------------------------------------------------
| Lightbox portfolios
|--------------------------------------------------------------------------
*/
.lightboxOverlay {
	background-color: rgba(65,64,66,0.95);
}
.lb-nav a.lb-prev {
	position: absolute;
	left: -15% !important;
	width: 49%;
}
.lb-nav a.lb-next {
	position: absolute;
	right: -15% !important;
	width: 79%;
}
.lb-data .lb-close {
	position: absolute;
    top: -8%;
    right: 20%;
}
.lightbox .lb-image {
	border: none;
}

/*
|--------------------------------------------------------------------------
| Maps
|--------------------------------------------------------------------------
*/

#map {
	width: 100%;
	height: 400px;
	background-color: $gray-light;
}