.process {
    h2 {
        color: $lightbrown;
        font-size: 0.9em;
        text-transform: none;
        font-weight: 400;
        margin-bottom: 25px;
    }

    strong {
        color: $lightbrown;
        font-weight: 400;
    }

    p {
        font-size: 13px;
        color: $gray-dark;
    }
}