.home {
	.box-shadow {
		top: 0;
		z-index: 1000;
		background-color: rgba(0, 0, 0, 0.1);
	}
}

section.portfolio {
	a {
		color: $gray-dark;
		&:hover,
		&.active {
			color: $gray-light;
		}
	}
	.proyect-box {
		margin: 15px 0;
		position: relative;
		text-align: center;
		.text-box {
			h2 {
				color: #FFFFFF;
				font-size: 1rem;
				word-break: break-word;
				text-decoration: none !important;
			}
			p {
				font-size: 1.25rem;
				color: #FFFFFF;
				text-align: center;
			}

			@media (min-width: 768px) {
				h2 {
					font-size: 2.5rem;
				}
			}
		}

	}
}

section.services {
	p {
		text-align: center;
	}
	.services-box {
		margin: 15px 0;
		position: relative;
		width: 100%;
		overflow: hidden;
		text-align: center;
		.box-shadow {
			top: 0;
		}
		.tpt-border {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 0.875rem;
			z-index: 2;
			.text-box {
				position: relative;
				transition: background-color 1s ease;
				transition: height 1s;
				overflow: hidden;
				.mCSB_draggerContainer {
					display: none;
				}

				h2 {
					color: white;
					font-size: 2rem;
					word-break: break-word;
				}
				.description {
					transition: opacity 1s ease-out;
					opacity: 0;
					display: none;
					p {
						color: $gray-dark;
						display: none;
						text-align: justify;
					}
				}
			}
		}
		&:hover {
			img {
				filter: blur(5px) grayscale(100%);
			}
			.tpt-border {
				.text-box {
					transition: background-color 1s ease;
					transition: height 1s;
					background: rgba(255, 255, 255, 0.5);
					height: 100%;
					padding: 2rem 1.6rem;
					.mCSB_draggerContainer {
						display: block;
						transition: all 0.1s;
					}
					h2 {
						color: $gray-dark;
						font-size: 20px;
						word-break: break-word;
					}
					.description {
						display: block;
						transition: opacity 1s ease-in;
						opacity: 1;
						p {
							display: block;
						}
					}
				}
			}
		}

		@media (max-width: 768px) {
			.tpt-border {
				.text-box {
					h2 {
						font-size: 1.5rem;
					}
					.description {
						p {
							font-size: 0.875rem;
						}
					}
				}
				&:hover {
					.text-box {
						padding: 1rem 0.8rem;
						h2 {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
	}
}

section.about {
	p {
		font-size: 15px;
		line-height: 1.5;
	}
	strong {
		color: $lightbrown;
	}
	p.phrase {
		font-size: 15px;
		line-height: 1.5;
		font-weight: 300;
		font-style: italic;
		color: $lightbrown;
	}
	img.firma {
		max-width: 280px;
		margin-top: 5px;
	}
}

section.instagram-wrapper {
	background-color: rgba(0, 0, 0, 0.2);
}

section.contact {
	h2 {
		font-size: 20px;
		color: $lightbrown;
		font-weight: normal;
		margin: 1rem 0;
	}
	a,
	p {
		color: $lightbrown;
		font-weight: 200;
	}
	a {
		display: inline-block;
		margin: 0;
		&:hover {
			text-decoration: none;
		}
	}
	p {
		margin: 5px 0;
	}
	@media (max-width: 768px) {
		p, a {
			font-size: 0.875rem;
		}
		img {
			width: 90%;
			float: right;
		}
	}
}

section.generales {
	height: 90%;
}

.int-port-body {
	h2 {
		font-weight: 400;
		font-size: 24px;
	}
	p.description {
		text-transform: uppercase;
		font-weight: 200;
		font-size: 1rem;
		margin: 1.4rem 0 1.8rem 0;
	}
	p {
		font-size: 14px;
		line-height: 1.5;
		color: black;
	}
}


/*
|--------------------------------------------------------------------------
|  Portfolio Images
|--------------------------------------------------------------------------
*/

figure.portfolio {
	overflow: hidden;
	margin-bottom: 0;
	transition: all 0.5s;
	width: 100%;
	img {
		width: 100%;
	}
	&.left {
		float: left;
	}
	&.right {
		float: right;
	}
	&.mw248 {
		// margin-bottom: 10px;
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 187px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 206px;
		}
		@media (min-width: 1200px) {
			max-width: 248px;
		}
	}
	&.mw260 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 194px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 218px;
		}
		@media (min-width: 1200px) {
			max-width: 260px;
		}
	}
	&.mw274 {
		@media (min-width: 768px) and (max-width: 991px) {
			 max-width: 208px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 232px;
		}
		@media (min-width: 1200px) {
			max-width: 274px;
		}
	}
	&.mw288 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 217px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 240px;
		}
		@media (min-width: 1200px) {
			max-width: 288px;
		}
	}
	&.mw314 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 235px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 264px;
		}
		@media (min-width: 1200px) {
			max-width: 314px;
		}
	}
	&.mw324 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 242px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 270px;
		}
		@media (min-width: 1200px) {
			max-width: 324px;
		}
	}
	&.mw333 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 235px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 276px;
		}
		@media (min-width: 1200px) {
			max-width: 333px;
		}
	}
	&.mw410 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 312px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 340px;
		}
		@media (min-width: 1200px) {
			max-width: 410px;
		}
	}
	&.mw480 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 365px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 400px;
		}
		@media (min-width: 1200px) {
			max-width: 480px;
		}
	}
	&.mw574 {
		max-width: none;
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 432px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 480px;
		}
		@media (min-width: 1200px) {
			max-width: 574px;
		}
	}
	&.mw586 {
		max-width: none;
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 440px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 494px;
		}
		@media (min-width: 1200px) {
			max-width: 586px;
		}
	}
	&.mw610 {
		max-width: none;
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 459px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 506px;
		}
		@media (min-width: 1200px) {
			max-width: 610px;
		}
	}
	&.mw616 {
		max-width: none;
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 475px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 522px;
		}
		@media (min-width: 1200px) {
			max-width: 616px;
		}
	}
	&.mw636 {
		max-width: none;
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 478px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 535px;
		}
		@media (min-width: 1200px) {
			max-width: 636px;
		}
	}
	&.mw640 {
		max-width: none;
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 480px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 530px;
		}
		@media (min-width: 1200px) {
			max-width: 640px;
		}
	} // Por Porcentaje
	&.mwa33 {
		margin-bottom: 10px;
		max-width: 30%;
		margin: 0;
		&:first-child {
			margin-left: 0;
		}
	}
}

#rot-slide06 {
	float: right;
	width: 100%;
	&.mw590 {
		@media (min-width: 768px) and (max-width: 991px) {
			max-width: 440px;
		}
		@media (min-width: 992px) and (max-width: 1999px) {
			max-width: 492px;
		}
		@media (min-width: 1200px) {
			max-width: 590px;
		}
	}
}
