@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px;
    }
}

@media (max-width: 360px), (max-width: 480px) {

    #home-slider {
        height: calc(100% - 33px);
    }

    header a.logo {
        margin: 10px auto 20px auto;
        img {
            max-width: 225px;
            float: left;
        }
    }
    #Hamburguer {
        font-size: 2em;
        padding: 15px;
        margin-top: 15px;
    }
    .text-slide {
        bottom: 45px;

        img {
            width: 80px !important;
        }
    }

    .mobile_padding_left {
        padding-left: 30px;
    }

    .mobile_padding_right {
        padding-right: 30px;
    }

}
