/*
|--------------------------------------------------------------------------
| Separators
|--------------------------------------------------------------------------
*/

.tpt {
	padding: 0.625rem;
	text-transform: uppercase;
	background-color: transparent;
	transition: all 0.7s;
	text-align: center;
	&:hover {
		text-decoration: none;
		transition: all 0.7s;
	}
	&.white {
		color: $white;
		border: 1px solid $white;
		&:hover {
			color: $gray-dark;
			background-color: rgba(255,255,255,0.5);
			border: 1px solid $white;
		}
	}
	&.gray {
		color: $gray;
		border: 1px solid $gray;
		&:hover {
			color: $white;
			background-color: $gray;
			border: 1px solid $gray;
		}
	}
}

.previous i {
	margin-right: 10px;
}

.next i {
	margin-left: 10px;
}

.previous,
a.next {
	color: $lightbrown;
	&:hover {
		color: $gray-dark;
	}
}


.close {
	padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
	float: right;
}