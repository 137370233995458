footer {
	background-color: white;
	padding: 5px;
	color: $lightbrown;
	position: relative;
	p, a {
		color: $lightbrown;
		font-size: 12px;
		margin: 0;
		display: inline-block;
		text-decoration: none;
	}

	a {
		&:hover {
			color: $lightbrown;
			text-decoration: none;
		}
	}

	img {
		display: inline-block;
		width: 80px;
		margin: 0 5px;
	}
	&.footer-home {
		@media (min-width: 1500px) {
			//position: fixed;
			width: 100%;
			z-index: 1000;
			//bottom: 0;
		}
	}
	.footer-desk {
		display: none;
		@media (min-width: 768px) {
			display: flex;
		}
	}
	.footer-mobil {
		display: flex;
		@media (min-width: 768px) {
			display: none;
		}
	}
	.fs-10 {
		* {
			font-size: 10px;
		}
	}
	.fs-9 {
		* {
			font-size: 9px;
		}
	}

	.crafted {
		height: 20px;
	}
}
