section.portfolio {
    .portfoliosubtitle {
        font-size: 0.8rem;
        font-weight: 400;
        text-align: center;
    }
    .proyect-box {
        height: 350px;
        overflow: hidden;

        .text-box {
            h2 {
                font-size: 1rem;
                text-decoration: none !important;
            }
        }

        a {
            img {
                transform: scale(1);
                transition: transform .5s ease-in-out;
            }
        }

        a:hover {
            img {
                transform: scale(1.2)
            }
        }
    }
}

.portfolio-intro, .portfolio-intro-minimal {
    padding: 10rem 1.5rem;
    font-size: 1em;
    margin-top: 10px;

    .port {
        font-size: 0.7rem;
        font-weight: 400;
        color: $lightbrown;
    }

    h1 {
        font-size: 2.8rem;
        line-height: 2.4rem;
        margin-top: 20px;
        margin-bottom: 20px;
        @media (max-width: 480px) {
			font-size: 1.8rem;
		}
    }

    h2 {
        font-size: 1rem;
        text-transform: none;
        color: $lightbrown;
    }

    p {
        margin-top: 24px;
    }

    .isotipoportfolio {
        width: 15%;
    }
}

.portfolio-intro-minimal {
    padding: 2rem 1.5rem;

    .isotipoportfolio {
        width: 50px;
        padding: 2rem 0;
    }
}

.mb-4 img {
    width: 100%;
}

.portfolio-height-482 {
    display: block;
    height: 482px;
    width: unset !important;
}

.portfolio-height-421 {
    display: block;
    height: 421px;
    width: unset !important;
    // @media (max-width: 768px) {
    //     width: 100%;
    //     height: unset !important;
    // }
    @media (min-width: 768px) and (max-width: 991px) {
        width: 100%;
        height: auto !important;
    }
}

// adaptación flex para casos de fotos de menor tamaño o donde se busca que queden más separadas
.port-wrapper-sm {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    div {
        margin-left: auto;
        order: 2;
    }
}
.port-wrapper-sm > div:first-child {
    float: left;
    margin-right: auto;
    margin-left: unset;
    order: 1;
    padding-right: 1.5rem;
}
.port-wrapper-sm img {
    display: block;
    max-width: 100%;
    max-height: 374px;
}
// adaptación flex para casos de fotos de tamaño mediano o con separación mediana
.port-wrapper-md {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    div {
        margin-left: auto;
        order: 2;
    }
}
.port-wrapper-md > div:first-child {
    float: left;
    margin-right: auto;
    margin-left: unset;
    order: 1;
    padding-right: 1.5rem;
}
.port-wrapper-md img {
    display: block;
    max-width: 100%;
    max-height: 420px;
}

// adaptación flex para casos de fotos de mayor o donde se busca que estén más cercanas
.port-wrapper-lg {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    div {
        margin-left: auto;
        order: 2;
    }
}
.port-wrapper-lg > div:first-child {
    float: left;
    margin-right: auto;
    margin-left: unset;
    order: 1;
    padding-right: 1.5rem;
}
.port-wrapper-lg img {
    display: block;
    max-width: 100%;
    max-height: 480px;
}