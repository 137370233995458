#home-slider {
	margin: 0;
	width: 100%;
	height: calc(100% - 67px);
	@media (min-width: 768px) { height: calc(100% - 53px);  }
	@media (min-width: 1297px) { height: calc(100% - 32px);  }
	@media (min-width: 1490px) { height: calc(100% - 31px);  }
	.item-slider {
		width: 100%;
		height: 100%;
		background-color: #f5f5f5;
		position: relative;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			min-width: 100%;
			min-height: 100%;
			max-width: none;
			max-height: none;
			transition: all ease 0.2s;
		}
	}

	.slick-list, .slick-track {
		height: 100%;
	}
	.slick-dots {
		position: absolute;
		height: 20px;
		z-index: 1000;
		display: none;
		bottom: 6%;
		li {
			margin-left: 1rem;
			border-radius: 10px;
			display: inline-block;
			background-color: rgba(255,255,255,0.5);
			button {
				border-radius: 10px;
			}

			button:before {
				content: ' ';
	    		color: transparent;
	    		border: none;
				font-family: 'FontAwesome', serif;
	    	}

			&.slick-active  {
				font-size: 1rem;
	        	background-color: $white;
	    	}
		}
		@media (max-width: 768px) {
			& {
				bottom: 2%;
				li {
					width: 10px;
					height: 10px;
				}
			}
		}
	}
	.slick-slider, .slick-slide {
		outline: none !important;
	}

}

#rot-slide01, #rot-slide02, #rot-slide03, #rot-slide04, #rot-slide05, #rot-slide06  {
	margin-bottom: 0;
	.slick-dots {
		position: absolute;
		height: 20px;
		z-index: 100;
		display: none;
		bottom: 5%;
		li {
			margin-left: 1rem;
			border-radius: 10px;
			display: inline-block;
			background-color: rgba(255,255,255,0.5);
			button {
				border-radius: 10px;
			}

			button:before {
				content: ' ';
	    		color: transparent;
	    		border: none;
	    		font-family: 'FontAwesome', sans-serif;
	    	}

			&.slick-active  {
				font-size: 1rem;
	        	background-color: $white;
	    	}
		}
	}

}

.text-slide {
	bottom: 30px;
    top: auto;
	pointer-events: none;
	position: absolute;
	width: 100%;
	text-align: center;
	// top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	h1 {
		font-weight: 300;
		color: $white;
		font-size: 60px;
	}
	a {
		display: inline-block;
		pointer-events: initial;
	}
	@media (orientation: landscape) and (max-width: 767px) {
		display: none;
		top: 55%;
		hr.separator {
			display: none;
		}
	}
	@media (max-width: 768px) {
		h1 {
			font-size: 1.2rem;
		}
		a {
			font-size: 14px;
		}
	}
}