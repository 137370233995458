/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
html {
	font-size: 16px;
	width: 100%;
	height: 100%;
}
body {
	position: relative;
	font-family: $font-montserrat;
	font-style: normal;
	font-size: 16px;
	font-weight: 200;
	line-height: 1.2;
	width: 100%;
	height: 100%;
	background-color: white;
	overflow: auto;
	&.body-home {
		overflow: hidden;
	}
	color: $lightbrown;
}

p {
	text-align: justify;
	line-height: 1.2;
	font-family: $font-montserrat;
	font-size: 16px;
	color: $lightbrown;
	font-weight: 200;
}

a {
	font-family: $font-montserrat;
	font-weight: 400;
	font-size: 16px;
	display: block;
	&:hover {
		text-decoration: none;
	}
	&.previous {
    	font-size: 14px !important;
		text-decoration: none;
	}
}

h1, h2, h3 {
	font-size: 1.5625rem;
	font-family: $font-montserrat;
	text-transform: uppercase;
	font-weight: 300;
	word-break: break-word;
	color: $lightbrown;

	@media (max-width: 768px) {
		font-size: 1.2rem;
	}
}

h1 {
	font-size: 2rem;
}

h3.portfoliotitle {
	font-size: 1.1rem;
	font-weight: 400;
	text-align: center;
}

img {
	max-width: 100%;
}


.text-slide {
    bottom: 30px;
    top: auto;
}

.mt-80 {
    margin-top: 80px;
}

.mb-40 {
    margin-bottom: 40px;
}

.pt-120 {
padding-top: 120px;
}

.pt-180 {
padding-top: 180px;
}

.pb-180 {
padding-bottom: 180px;
}

.bg-raices-light {
background-color: $formbrown;
}

.color-brand {
	color: #9f968a;
}

.socialcontact {
	margin-right: 10px;
}

.btn-raices-dark {
background-color: $lightbrown;
color: white;
border-radius: 0;

&:hover {
	background-color: $mediumbrown;
	color: white;
}
}
