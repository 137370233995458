form.contact{
	input, textarea {
		border-radius: 0;
		width: 100%;
		padding: 10px;
		margin: 8px 0;
		p {
			color: $gray-dark;
		}
		background-color: $formbrown;
	}

	input.tpt {
		padding: 1rem;
	}

	h2 {
		color: $lightbrown;
	}

	#rc-imageselect, .g-recaptcha {transform:scale(0.77);transform-origin:0;transform:scale(0.77);transform-origin:0 0; }
}